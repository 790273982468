<template>
    <div class="image_01">
        <img src="../assets/hfjkdsljl.jpg" id="headshot" title="Fenghao Lu">
    </div>
</template>
<script>
    export default{}
</script>
<style scoped>
#headshot {
    max-width: 300px;
}
</style>
