<template>
    <div class="icp">
        <p  class="icp">
        <a class="banquan" href="http://beian.miit.gov.cn/" target="_blank">京ICP备2023033298号

        </a></p>
        <p class="pl"><img style="padding-top:2px;" src="../assets/lo.png">
        <a class="banquan" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010802043543"
        target="_blank">京公网安备11010802043543</a></p>
    </div>
</template>

<style scoped>
.icp {
     background:none;
    float: left;
    width: 100%;
    height: 5%;
    /* padding-top: 32px; */
    /* padding-bottom: 32px; */
    /* align-items: center; */
    /* color: #888; */
    /* background-color: #f3f3f3; */
    text-align: center;
    margin-top: 14rem; 
   margin-bottom: 0rem;
}
.pl{
    background:none;
    float: left;
    width: 100%;
    height: 5%;
    /* padding-top: 32px; */
    /* padding-bottom: 32px; */
    /* align-items: center; */
    /* color: #888; */
    /* background-color: #f3f3f3; */
    text-align: center;
    margin-top: 1.2rem; 
}
a {
    color:#000;
}
</style>
