<template>
    <div>
    <h1><a  title="Fenghao Lu - China University of Geosciences Beijing">Fenghao Lu</a></h1>
    </div>
</template>
<script>
export default{}
</script>
<style scoped>
    a {
    color: #990000;
    text-decoration: none;
    font-weight: bold;
}
</style>